import { template as template_7d477bec122044a8bb5b632d028bb0e0 } from "@ember/template-compiler";
export default template_7d477bec122044a8bb5b632d028bb0e0(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
