import { template as template_6b91b3f07e694f2b8c167d38f6784c71 } from "@ember/template-compiler";
import PixButton from '@1024pix/pix-ui/components/pix-button';
import PixModal from '@1024pix/pix-ui/components/pix-modal';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { htmlUnsafe } from 'mon-pix/helpers/html-unsafe';
export default class ModulixImageElement extends Component {
    @tracked
    modalIsOpen = false;
    get hasAlternativeText() {
        return this.args.image.alternativeText.length > 0;
    }
    @action
    showModal() {
        this.modalIsOpen = true;
        this.args.onAlternativeTextOpen(this.args.image.id);
    }
    @action
    closeModal() {
        this.modalIsOpen = false;
    }
    get hasCaption() {
        return this.args.image.legend?.length > 0 || this.args.image.licence?.length > 0;
    }
    static{
        template_6b91b3f07e694f2b8c167d38f6784c71(`
    <div class="element-image">
      <figure class="element-image__container">
        <img class="element-image-container__image" alt={{@image.alt}} src={{@image.url}} />
        {{#if this.hasCaption}}
          <figcaption class="element-image-container__caption">{{@image.legend}}<span
              class="element-image-container__licence"
            >{{@image.licence}}</span></figcaption>
        {{/if}}
      </figure>
      {{#if this.hasAlternativeText}}
        <PixButton class="element-image__button" @variant="tertiary" @triggerAction={{this.showModal}}>
          {{t "pages.modulix.buttons.element.alternativeText"}}
        </PixButton>
        <PixModal
          @title={{t "pages.modulix.modals.alternativeText.title"}}
          @showModal={{this.modalIsOpen}}
          @onCloseButtonClick={{this.closeModal}}
        >
          <:content>
            {{htmlUnsafe @image.alternativeText}}
          </:content>
        </PixModal>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
