import { template as template_3ea90d3106fc48068fe898a982393e3d } from "@ember/template-compiler";
import { element } from 'ember-element-helper';
import ModuleElement from './module-element';
export default class ModulixCustomElement extends ModuleElement {
    static{
        template_3ea90d3106fc48068fe898a982393e3d(`
    <div class="element-custom">
      {{#let (element @component.tagName) as |Tag|}}
        <Tag props={{@component.props}} />
      {{/let}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
