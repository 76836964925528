import { template as template_1a42872b99724995a8808adafce2d2a5 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { eq } from 'ember-truth-helpers';
export default template_1a42872b99724995a8808adafce2d2a5(`
  <button
    class="monpix-tabs__tab"
    id="{{@id}}-{{@index}}"
    type="button"
    role="tab"
    aria-selected={{if (eq @currentTab @index) "true" "false"}}
    aria-controls="panel-{{@id}}-{{@index}}"
    tabindex={{if (eq @currentTab @index) "0" "-1"}}
    {{on "click" (fn @onTabClick @index)}}
  >
    <span>
      {{yield}}
    </span>
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
